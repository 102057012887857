<template>
  <div>
    <router-link class="nav-link" to="/">Home</router-link>
    <router-link class="nav-link" to="/search">Horses for Sale</router-link>
    <router-link class="nav-link" to="/save-listing">List a Horse for Sale</router-link>
    <div class="dropdown">
      <a class="dropdown-toggle" data-toggle="dropdown">Equine Services</a>
      <div class="dropdown-menu">
        <router-link class="nav-link" to="/search-service-providers?type=Any">All Equine Services</router-link>
        <router-link class="nav-link" to="/search-service-providers?type=Real+Estate">Real Estate Listings</router-link>
        <router-link class="nav-link" to="/search-service-providers?type=Job+Listings">Job Listings</router-link>
      </div>
    </div>
    <router-link class="nav-link" to="/search-equipment?type=Any">Equipment</router-link>
    <router-link class="nav-link" to="/trainer-package">Trainer/Breeder Package</router-link>
    <router-link class="nav-link" :to="{ path: '/about', hash: '#press' }">In The Press</router-link>
    <router-link class="nav-link" to="/about">About</router-link>
    <router-link class="nav-link" to="/faq">FAQ's/Tutorials</router-link>
  </div>
</template>
