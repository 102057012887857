import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '@/firebase'
import Home from '@/views/Home.vue'
import $ from 'jquery';

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/terms-of-use',
    component: () => import(/* webpackChunkName: "terms_of_use" */ '../views/TermsOfUse.vue')
  },
  {
    path: '/privacy-policy',
    component: () => import(/* webpackChunkName: "privacy_policy" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/benefits-of-msm',
    component: () => import(/* webpackChunkName: "benefits_of_msm" */ '../views/BenefitsOfMSM.vue')
  },
  {
    path: '/get-the-msm-app',
    component: () => import(/* webpackChunkName: "get_the_msm_app" */ '../views/GetTheMSMApp.vue')
  },
  {
    path: '/faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
  },
  {
    path: '/contact-us',
    component: () => import(/* webpackChunkName: "contact_us" */ '../views/ContactUs.vue')
  },
  {
    path: '/my-ads',
    component: () => import(/* webpackChunkName: "my_ads" */ '../views/MyAds.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/save-ads',
    component: () => import(/* webpackChunkName: "save_ads" */ '../views/SaveAds.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-listings',
    component: () => import(/* webpackChunkName: "my_listings" */ '../views/MyListings.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/save-listing',
    component: () => import(/* webpackChunkName: "save_listing" */ '../views/SaveListing.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-services',
    component: () => import(/* webpackChunkName: "my_services" */ '../views/MyServices.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/save-service',
    component: () => import(/* webpackChunkName: "save_service" */ '../views/SaveService.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/save-real-estate',
    component: () => import(/* webpackChunkName: "save_real_estate" */ '../views/SaveRealEstate.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/save-job',
    component: () => import(/* webpackChunkName: "save_job" */ '../views/SaveJob.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-equipment',
    component: () => import(/* webpackChunkName: "my_equipment" */ '../views/MyEquipments.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/save-equipment',
    component: () => import(/* webpackChunkName: "save_equipment" */ '../views/SaveEquipment.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-saved-horses',
    component: () => import(/* webpackChunkName: "my_saved_horses" */ '../views/MySavedHorses.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-saved-services',
    component: () => import(/* webpackChunkName: "my_saved_services" */ '../views/MySavedServices.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-saved-equipment',
    component: () => import(/* webpackChunkName: "my_saved_equipment" */ '../views/MySavedEquipments.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-saved-filters',
    component: () => import(/* webpackChunkName: "my_saved_filters" */ '../views/MySavedFilters.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-profile',
    component: () => import(/* webpackChunkName: "my_profile" */ '../views/MyProfile.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/manage-subscription',
    component: () => import(/* webpackChunkName: "manage_subscription" */ '../views/ManageSubscription.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/my-payment-methods',
    component: () => import(/* webpackChunkName: "my_payment_methods" */ '../views/MyPaymentMethods.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/subscribe/:id',
    name: 'subscribe',
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/Subscribe.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/change-listing-plan/:id',
    name: 'change-listing-plan',
    component: () => import(/* webpackChunkName: "change_listing_plan" */ '../views/ChangeListingPlan.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/change-service-plan/:id',
    name: 'change-service-plan',
    component: () => import(/* webpackChunkName: "change_service_plan" */ '../views/ChangeServicePlan.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/change-equipment-plan/:id',
    name: 'change-equipment-plan',
    component: () => import(/* webpackChunkName: "change_equipment_plan" */ '../views/ChangeEquipmentPlan.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/change-ad-plan/:id',
    name: 'change-ad-plan',
    component: () => import(/* webpackChunkName: "change_ad_plan" */ '../views/ChangeAdPlan.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/plans',
    component: () => import(/* webpackChunkName: "plans" */ '../views/Plans.vue')
  },
  {
    path: '/ads-plans',
    component: () => import(/* webpackChunkName: "ads_plans" */ '../views/AdsPlans.vue')
  },
  {
    path: '/search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue')
  },
  {
    path: '/search-service-providers',
    component: () => import(/* webpackChunkName: "search_service_providers" */ '../views/SearchService.vue')
  },
  {
    path: '/search-equipment',
    component: () => import(/* webpackChunkName: "search_equipment" */ '../views/SearchEquipments.vue')
  },
  {
    path: '/search-equipments',
    redirect: '/search-equipment'
  },
  {
    path: '/manage-listings',
    component: () => import(/* webpackChunkName: "manage_listings" */ '../views/ManageListings.vue')
  },
  {
    path: '/trainer-package',
    component: () => import(/* webpackChunkName: "trainer_package" */ '../views/TrainerPackage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        el: to.hash
      };
    }
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/');
  }

  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    try {
      if ($('#loginModal').length > 0) {
        $('#loginModal').modal('show');
        $('#myTab a[href="#sign-in-content"]').tab('show');
      } else {
        next('/');
      }
    } catch {
      next('/');
    }
    
  } else {
    next();
  }
})

export default router
